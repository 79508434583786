.guest-bg-image {
  background-image: url('../images/guest-bg-image.svg');
  background-size: auto 100%;
  background-repeat: no-repeat;
}

@font-face {
  font-family: 'Degular';
  font-weight: 700;
  src: url('../fonts/Degular-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Degular';
  font-weight: 500;
  src: url('../fonts/Degular-Medium.otf') format('opentype');
}

body {
  font-family: 'Degular';
  font-weight: 500;
}
